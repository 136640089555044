





































































































































































































.shell {
  width: 100%;
  overflow: hidden;
  .l {
    width: 30%;
    float: left;
  }
  .r {
    width: 70%;
    float: left;
  }
  .p2 {
    margin-top: 20px;
  }
}
